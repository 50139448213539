import { useNavigate, useRevalidator } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { $path } from 'remix-routes';

import type {
  DtoGroupAssignedCourse,
  DtoGroupAssignedStack,
  DtoGroupDetailResponse,
} from '@lp-lib/api-service-client/public';

import placeholder from '../../../../assets/img/placeholder/game-cover.png';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { getLogger } from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { EditIcon } from '../../../icons/EditIcon';
import { GameIcon } from '../../../icons/GameIcon';
import { assessmentScoreToColor } from '../assessmentScoreToColor';
import { ActionsMenu } from '../Components/ActionMenu';
import { PageHeader } from '../Components/PageHeader';
import { useTriggerAddCoursesToGroupModal } from '../useTriggerAddCoursesToGroupModal';
import { useTriggerAddMembersToGroupModal } from '../useTriggerAddMembersToGroupModal';
import { useTriggerAddStacksToGroupModal } from '../useTriggerAddStacksToGroupModal';

const logger = getLogger();

interface GroupDetailsPageProps {
  group: DtoGroupDetailResponse;
}

function GroupNameField({
  name,
  onSave,
}: {
  name: string;
  onSave: (newName: string) => void;
}) {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(name);

  useEffect(() => {
    setInputValue(name);
  }, [name]);

  const handleSave = (newName: string) => {
    const trimmedName = newName.trim();
    if (trimmedName && trimmedName !== name) {
      onSave(trimmedName);
    }
    setEditing(false);
  };

  if (!editing) {
    return (
      <div className='flex items-center gap-2'>
        <h1 className='text-2xl font-bold'>{name}</h1>
        <button
          type='button'
          className='p-2 rounded hover:bg-[#232325] transition-colors'
          onClick={() => setEditing(true)}
        >
          <EditIcon className='w-5 h-5 fill-current text-white/70 hover:text-white' />
        </button>
      </div>
    );
  }

  return (
    <input
      className='field mb-0 w-50 h-8'
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={(e) => handleSave(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSave(e.currentTarget.value);
        } else if (e.key === 'Escape') {
          setEditing(false);
          setInputValue(name);
        }
      }}
      autoFocus
    />
  );
}
type CourseRowProps = {
  course: DtoGroupAssignedCourse;
  onRemove: (courseId: string) => void;
};

function CourseRow({ course, onRemove }: CourseRowProps) {
  const navigate = useNavigate();
  const navigateToCourse = () => {
    navigate($path('/learning/admin/my-courses/:id', { id: course.courseId }));
  };

  const formatScore = (score: number | null | undefined) => {
    if (score === null || score === undefined) return 'N/A';
    return `${Math.round(score * 100)}%`;
  };

  const actions = [
    {
      label: 'Course Details',
      icon: <GameIcon className='w-6 h-6 fill-current text-white pr-2' />,
      onClick: navigateToCourse,
    },
    {
      label: 'Edit Course',
      icon: <EditIcon className='w-6 h-6 fill-current text-white pr-2' />,
      onClick: () =>
        navigate($path('/trainings/:id/edit', { id: course.courseId })),
    },
    {
      label: 'Remove Course',
      icon: <DeleteIcon className='w-6 h-6 fill-current pr-2' />,
      onClick: () => onRemove(course.courseId),
      variant: 'danger' as const,
    },
  ];

  return (
    <div
      className='bg-[#17171A] hover:bg-[#1f1f22] transition-colors border border-lp-gray-003 rounded-lg px-4 py-3 flex justify-between items-center hover:cursor-pointer'
      onClick={navigateToCourse}
    >
      <div className='flex-1 flex items-center gap-4'>
        <img
          src={course.cover?.url || placeholder}
          alt={course.courseName}
          className='w-21 h-12 rounded-lg object-cover'
        />
        <div className='text-white font-medium'>{course.courseName}</div>
      </div>
      <div
        className={`w-48 text-center ${assessmentScoreToColor(
          course.avgAssessmentScore
        )}`}
      >
        {formatScore(course.avgAssessmentScore)}
      </div>
      <ActionsMenu actions={actions} />
    </div>
  );
}

type StackRowProps = {
  stack: DtoGroupAssignedStack;
  onRemove: (stackId: string) => void;
};

function StackRow({ stack, onRemove }: StackRowProps) {
  const navigate = useNavigate();
  const navigateToStack = () => {
    navigate(
      $path('/learning/admin/my-courses/stacks/:id', { id: stack.stackId })
    );
  };

  const actions = [
    {
      label: 'Stack Details',
      icon: <GameIcon className='w-6 h-6 fill-current text-white pr-2' />,
      onClick: navigateToStack,
    },
    {
      label: 'Remove Stack',
      icon: <DeleteIcon className='w-6 h-6 fill-current pr-2' />,
      onClick: () => onRemove(stack.stackId),
      variant: 'danger' as const,
    },
  ];

  return (
    <div
      className='bg-[#17171A] hover:bg-[#1f1f22] transition-colors border border-lp-gray-003 rounded-lg px-4 py-3 flex justify-between items-center hover:cursor-pointer'
      onClick={navigateToStack}
    >
      <div className='flex items-center gap-4'>
        <div className='w-21 h-12 rounded-lg bg-gray-700 overflow-hidden flex items-center justify-center'>
          <span className='text-2xl'>📚</span>
        </div>
        <div>
          <div className='text-white font-medium'>{stack.stackName}</div>
          <div className='text-white/70 text-sm'>
            {stack.courseCount} courses
          </div>
        </div>
      </div>
      <ActionsMenu actions={actions} />
    </div>
  );
}
type MemberRowProps = {
  uid: string;
  name: string;
  email: string;
  assignedOn: string;
  addedBy: string;
  onRemove: () => void;
};

function MemberRow({
  uid,
  name,
  email,
  assignedOn,
  addedBy,
  onRemove,
}: MemberRowProps) {
  const navigate = useNavigate();
  const handleClick = useLiveCallback(() => {
    navigate($path('/learning/admin/learners/:id/profile', { id: uid }));
  });
  const actions = [
    {
      label: 'Remove Member',
      icon: <DeleteIcon className='w-6 h-6 fill-current pr-2' />,
      onClick: () => onRemove(),
      variant: 'danger' as const,
    },
  ];

  const displayedName = name.trim() === '' ? 'N/A' : name;

  return (
    <div
      className='bg-[#17171A] hover:bg-[#1f1f22] transition-colors border border-lp-gray-003 rounded-lg px-4 py-3 flex items-center cursor-pointer'
      onClick={handleClick}
    >
      <div className='flex-1'>
        <div className='text-white font-medium'>{displayedName}</div>
        <div className='text-white/70 text-sm'>{email}</div>
      </div>
      <div className='flex-1 text-white/70'>
        {new Date(assignedOn).toLocaleDateString()}
      </div>
      <div className='flex-1 text-white/70'>{addedBy}</div>
      <ActionsMenu actions={actions} className='w-50' />
    </div>
  );
}

export function GroupDetailsPage({ group }: GroupDetailsPageProps) {
  const revalidator = useRevalidator();
  const [optimisticName, setOptimisticName] = useState(group.name);

  const triggerAddStacks = useTriggerAddStacksToGroupModal();
  const triggerAddCourses = useTriggerAddCoursesToGroupModal();
  const triggerAddMembers = useTriggerAddMembersToGroupModal();

  const handleUpdateGroupName = async (newName: string) => {
    setOptimisticName(newName);
    try {
      await apiService.learning.updateGroupName(group.id, { name: newName });
      toast.success('Group name updated');
      revalidator.revalidate();
    } catch (error) {
      setOptimisticName(group.name);
      logger.error('Failed to update group name', error);
      toast.error('Failed to update group name');
    }
  };

  const handleRemoveMember = async (userId: string) => {
    try {
      await apiService.learning.removeMemberFromGroup(group.id, userId);
      toast.success('Member removed from group');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to remove member', error);
      toast.error('Failed to remove member');
    }
  };

  const handleRemoveCourse = async (courseId: string) => {
    try {
      await apiService.learning.removeEnrollmentFromGroup(
        group.id,
        'course',
        courseId
      );
      toast.success('Course removed from group');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to remove course', error);
      toast.error('Failed to remove course');
    }
  };

  const handleRemoveStack = async (stackId: string) => {
    try {
      await apiService.learning.removeEnrollmentFromGroup(
        group.id,
        'stack',
        stackId
      );
      toast.success('Stack removed from group');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to remove stack', error);
      toast.error('Failed to remove stack');
    }
  };

  const handleAddCourses = async (courseIds: string[]) => {
    try {
      await apiService.learning.addEnrollmentsToGroup(group.id, {
        type: 'course',
        objectIds: courseIds,
      });
      toast.success('Courses added to group');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to add courses', error);
      toast.error('Failed to add courses');
    }
  };

  const handleAddStacks = async (stackIds: string[]) => {
    try {
      await apiService.learning.addEnrollmentsToGroup(group.id, {
        type: 'stack',
        objectIds: stackIds,
      });
      toast.success('Stacks added to group');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to add stacks', error);
      toast.error('Failed to add stacks');
    }
  };

  const handleAddMembers = async (emails: string[]) => {
    try {
      await apiService.learning.addMembersToGroup(group.id, { emails });
      toast.success('Members added to group');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to add members', error);
      toast.error('Failed to add members');
    }
  };

  return (
    <div className='flex flex-col w-full text-white max-w-screen-2xl mx-auto relative'>
      {/* Header */}
      <div className='flex items-center justify-between w-full px-4 pt-4 md:pt-8 pb-4'>
        <div className='flex flex-col gap-1'>
          <PageHeader title='Groups' path={'/learning/admin/groups'} />
          <GroupNameField
            name={optimisticName}
            onSave={handleUpdateGroupName}
          />
        </div>
        <div className='flex items-center'>
          {group.avgAssessmentScore !== null &&
            group.avgAssessmentScore !== undefined && (
              <div className='text-center'>
                <div
                  className={`text-2xl font-bold ${assessmentScoreToColor(
                    group.avgAssessmentScore
                  )}`}
                >
                  {Math.round(group.avgAssessmentScore * 100)}%
                </div>
                <div className='text-xs text-white mt-1'>Average Score</div>
              </div>
            )}
        </div>
      </div>

      {/* Content */}
      <div className='flex-1 overflow-y-auto pb-40 md:pb-0'>
        {/* Courses Section */}
        <div className='px-4 pt-4'>
          <div className='flex items-center justify-between mb-4'>
            <h2 className='text-xl font-bold'>
              Courses ({group.assignedCourses.length})
            </h2>
            <button
              className='btn-secondary px-4 py-2'
              type='button'
              onClick={() => {
                const existingCourseIds = group.assignedCourses.map(
                  (course) => course.courseId
                );
                triggerAddCourses({
                  existingCourseIds,
                  onConfirm: handleAddCourses,
                });
              }}
            >
              Add Courses
            </button>
          </div>

          {group.assignedCourses.length > 0 ? (
            <>
              <div className='hidden md:flex w-full px-4 py-2 items-center text-gray-400 text-xs uppercase font-medium mb-2'>
                <div className='flex-1'>Course Name</div>
                <div className='w-48 text-center'>Avg Assessment Score</div>
                <div className='w-12'></div>
              </div>

              <div className='flex flex-col gap-2'>
                {group.assignedCourses.map((course) => (
                  <CourseRow
                    key={course.courseId}
                    course={course}
                    onRemove={handleRemoveCourse}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className='bg-[#17171A] border border-lp-gray-003 rounded-lg p-6 text-center text-gray-400'>
              <div className='mb-2'>No courses assigned to this group yet</div>
              <div className='text-sm'>
                Add courses to start assigning content to this group
              </div>
            </div>
          )}
        </div>

        {/* Stacks Section */}
        <div className='px-4 pt-16'>
          <div className='flex items-center justify-between mb-4'>
            <h2 className='text-xl font-bold'>
              Stacks ({group.assignedStacks.length})
            </h2>
            <button
              className='btn-secondary px-4 py-2'
              type='button'
              onClick={() => {
                const existingStackIds = group.assignedStacks.map(
                  (stack) => stack.stackId
                );
                triggerAddStacks({
                  existingStackIds,
                  onConfirm: handleAddStacks,
                });
              }}
            >
              Add Stacks
            </button>
          </div>

          {group.assignedStacks.length > 0 ? (
            <div className='flex flex-col gap-2'>
              {group.assignedStacks.map((stack) => (
                <StackRow
                  key={stack.stackId}
                  stack={stack}
                  onRemove={handleRemoveStack}
                />
              ))}
            </div>
          ) : (
            <div className='bg-[#17171A] border border-lp-gray-003 rounded-lg p-6 text-center text-gray-400'>
              <div className='mb-2'>No stacks assigned to this group yet</div>
              <div className='text-sm'>
                Add stacks to assign multiple courses at once
              </div>
            </div>
          )}
        </div>

        {/* Members Section */}
        <div className='px-4 pt-16'>
          <div className='flex items-center justify-between mb-4'>
            <h2 className='text-xl font-bold'>
              Members ({group.assignedMembers.length})
            </h2>
            <button
              type='button'
              className='btn-secondary px-4 py-2'
              onClick={() => {
                triggerAddMembers({
                  group: {
                    id: group.id,
                    name: group.name,
                  },
                  onConfirm: handleAddMembers,
                });
              }}
            >
              Add Members
            </button>
          </div>

          {group.assignedMembers.length > 0 ? (
            <>
              <div className='hidden md:flex w-full px-4 py-2 items-center text-gray-400 text-xs uppercase font-medium mb-2'>
                <div className='flex-1'>Name</div>
                <div className='flex-1'>Assigned On</div>
                <div className='flex-1'>Assigned By</div>
                <div className='w-12'></div>
              </div>

              <div className='flex flex-col gap-2 pb-6'>
                {group.assignedMembers.map((member) => (
                  <MemberRow
                    key={member.userId}
                    uid={member.userId}
                    name={member.name}
                    email={member.email}
                    assignedOn={member.assignedOn}
                    addedBy={member.addedBy}
                    onRemove={() => handleRemoveMember(member.userId)}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className='bg-[#17171A] border border-lp-gray-003 rounded-lg p-6 text-center text-gray-400'>
              <div className='mb-2'>No members assigned to this group yet</div>
              <div className='text-sm'>
                Add members to assign them to all group courses
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
